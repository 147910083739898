import { Box } from "@mui/material";

function Content() {
  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: {
          xs: `url("/coffee-machine.gif")`,
          sm: `url("/coffee-machine.gif")`,
          md: `url("/coffee-machine.gif")`,
          lg: `url("/coffee-machine-large.gif")`,
          xl: `url("/coffee-machine-large.gif")`,
        },
        height: {
          xs: "110vh",
          sm: "110vh",
          md: "250vh",
          lg: "130vh",
          xl: "150vh",
        },
        margin: 0,
      }}
    ></Box>
  );
}

export default Content;
