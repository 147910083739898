import React from "react";
import { Box } from "@mui/material";
import MenuLanguage from "./MenuLanguage";

const NavBar = () => {
  return (
    <Box
    sx={{
      position: "sticky",
      top: 0, 
      width: "100%",
      zIndex: 1000,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      backgroundColor: "transparent !important",
      blur: "10px",
      backdropFilter: "blur(10px)",
    }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
          color: "white",
          height: "5rem   ",
          position: "relative",
        }}
      >
        <Box>
          <img
            width={50}
            height={50}

            src="/barazil-logo.png"
            alt="Brazil Castelo"
          />
        </Box>
        <MenuLanguage />
      </Box>
    </Box>
  );
};

export default NavBar;
