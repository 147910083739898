import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ca9a33",
      100: "#dcc793",
    },
    secondary: {
      main: "#1c0d08",
      100: "#eeeef0",
    },
  },
  typography: {
    fontFamily: "Arabic, Roboto, sans-serif",
  },
});

export default theme;
