import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    resources: {
      en: {
        translation: {
          "Welcome to React": "Welcome to React and react-i18next",
          "About Us": "About Us",
          description:
            "At Brazil Castelo, we are passionate about serving the freshest, locally sourced ingredients in our dishes. Our menu is carefully crafted to showcase the natural flavors of each ingredient. We believe in supporting our community and building relationships with our customers.",
          Home: "Home",
          Services: "Services",
          "Contact Us": "Contact Us",
          Copyright: "All rights reserved",
          "Email: ": "Email: ",
          by: "Designed and Developed by",
          quote: "100% Arabica Brazilian Specialty Coffee",
          Phone: "Phone",
          Number: "+966 502 093 001",
          Product: "Product",
          ProductName: "Mogiana",
          Variety: "Variety",
          VarietyType: "Yellow and Red Catuai, Mundo Novo, Arara",
          Processing: "Processing",
          ProcessingType: "Dry Processed",
          Altitude: "Altitude",
          AltitudeType: "1200 meters",
          Score: "Score",
          ScoreType: "84",
          Flavors: "Flavors",
          FlavorsType: "Chocolate, Caramel, Nuts",
          ProductDescription1:
            "Fazenda Santa Cecília is a farm covering an area of 90 hectares in Brazil. Its altitude ranges between 837 and 920 meters above sea level, benefiting from terraced planting and modern equipment. The Perbone family has been cultivating coffee on this farm since 2015, ensuring an ideal environment for coffee farming.",
          ProductDescription2:
            "The farm produces a variety of coffee strains, such as Yellow Catuai, Mundo Novo, and Arara. This year, the Arara strain has been introduced, which is expected to add unique flavors to the product.",
          ProductDescription3:
            "The coffee cherries are selectively handpicked from the trees (depending on size), dried using family techniques, and carefully monitored to ensure quality and preserve the authenticity of the coffee's origin.",
          ContactUs: "Contact Us",
        },
      },
      ar: {
        translation: {
          "Welcome to React": "مرحب بك في مشروعنا بواسطة شيماء محمد ومحمود علي",

          "About Us": "من نحن",
          description:
            "في برازيل كاستيلو، نحن متحمسون لتقديم المكونات الطازجة من مصادر محلية في أطباقنا. تم تصميم قائمتنا بعناية لعرض النكهات الطبيعية لكل مكون. نحن نؤمن بدعم مجتمعنا وبناء العلاقات مع عملائنا.",

          "Quick Links": "روابط سريعة",
          Home: "الرئيسية",
          Services: "خدمات",
          "Contact Us": "اتصل بنا",
          "Email: ": "البريد الالكتروني: ",
          Copyright: "جميع الحقوق محفوظة",
          by: "تصميم وتطوير بواسطة",
          quote: "القهوة البرازيلية المختصة ارابيكا ١٠٠٪",
          Phone: "الهاتف",
          Number: "001 093 502 966+",
          Product: "المنتج",
          ProductName: "موجيانا",
          Variety: "السلالة",
          VarietyType: "كاتورا صفراء وحمراء، مندو نوفو، أرارا",
          Processing: "المعالجة",
          ProcessingType: "مجففة",
          Altitude: "الارتفاع",
          AltitudeType: "١٢٠٠ متر",
          Score: "التقييم",
          ScoreType: "٨٤",
          Flavors: "النكهات",
          FlavorsType: "شوكولاتة، كراميل، مكسرات",
          ProductDescription1:
            "Fazenda Santa Cecília هي مزرعة تبلغ مساحتها 90 هكتارًا في البرازيل، ويتراوح ارتفاعها عن سطح البحر ما بين 837 و920 مترًا بفضل التضاريس السلسة والمعدات الحديثة. تقوم عائلة Perbone بزراعة القهوة في هذه المزرعة منذ عام 2015 مع وجود خبرة لخلق بيئة مثالية لزراعة البن.",
          ProductDescription2:
            "تنتج المزرعة العديد من السلالات مثل الكاتواي الصفراء، ومندو نوفو، وأرارا. وقد شهد هذا العام تقديم سلالة أرارا  Arara مما من المؤكد أنه سيضيف نكهة فريدة للمحصول.",
          ProductDescription3:
            "حصد البن في هذه المزرعة يتم (حسب الحجم)، ويتم تجفيف البن باستخدام الأسرة والتأكد من أن كل دفعة تلبي معايير الجودة التي تعكسها مصادر القهوة",
          ContactUs: "اتصل بنا",
        },
      },
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
