import React from "react";
import { Box } from "@mui/material";

const Header = () => {
  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: {
          xs: `url("/BrownCoffeeSmall.gif")`,
          sm: `url("/BrownCoffeeSmall.gif")`,
          md: `url("/BrownCoffeeSmall.gif")`,
          lg: `url("/Brown.gif")`,
          xl: `url("/Brown.gif")`,
        },
        height: {
          xs: "110vh",
          sm: "110vh",
          md: "200vh",
          lg: "110vh",
          xl: "110vh",
        },
        margin: 0,
      }}
    ></Box>
  );
};

export default Header;
