import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Quote = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 1,
        textAlign: "center",
        margin: 0,
        backgroundImage: `linear-gradient(rgba(111, 78, 55, .8), rgba(111, 78, 55, .8)), url("/QuotePanner.png")`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        minHeight: "40vh",
      }}
    >
      <Grid2 container spacing={2} justifyContent="center" alignItems="center">
        <Grid2 size={{ xs: 12 }} alignItems="center">
          <Box
            sx={{
              color: "#f3e5ab",
              fontFamily: "Arabic",
              textAlign: "center",
              fontSize: { xs: "1.5rem", md: "2rem" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="h4" component="div">
              " {t("quote")} "
            </Typography>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Quote;
