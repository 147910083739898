import React, { useEffect } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const MenuLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = React.useState("en");

  useEffect(() => {
    document.documentElement.lang = i18n.language;

    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
  }, [i18n.language]);

  return (
    <Box>
      <FormControl>
        <Select
          size="small"
          value={language}
          defaultValue={i18n.language || "en"}
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            setLanguage(e.target.value);
          }}
          sx={{
            color: "white",
            backgroundColor: (theme) => theme.palette.primary.main,
            transition: "ease-in-out 0.5s",
            borderRadius: "12px",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
            border: "none",
            padding: "2px 2px",
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            "&:focus": {
              backgroundColor: "primary",
              outline: "none",
            },
            "& .MuiSelect-icon": {
              color: "white",
            },
            "& .Mui-selected": {
              backgroundColor: "secondary", // Custom background for selected item
              color: "white", // Custom text color for selected item
            },
            "& .MuiMenuItem-root": {
              backgroundColor: "#f4f4f4", // Default background for menu items
            },
          }}
        >
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "black",
              "&.Mui-selected": {
                backgroundColor: "secondary !important", // Override selected item background
                // color: "white !important", // Override selected item text color
              },
              "&:hover": {
                backgroundColor: "secondary",
                // color: "white",
              },
            }}
            value="en"
          >
            <Typography>English</Typography>
          </MenuItem>
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              color: "black",
              "&.Mui-selected": {
                backgroundColor: "secondary !important", // Override selected item background
                // color: "white !important", // Override selected item text color
              },
              "&:hover": {
                backgroundColor: "secondary",
                // color: "white",
              },
            }}
            value="ar"
          >
            <Typography>العربية</Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default MenuLanguage;
