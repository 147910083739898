import { useEffect } from "react";
import { useTranslation } from "react-i18next";
// ** Material theme
import { ThemeProvider } from "@mui/material";
import { CssBaseline } from "@mui/material";
import theme from "./theme/theme";
import "./App.css";
// ** Custom Components
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import Content from "./components/Content";
import Quote from "./components/Quote";
import CardProduct from "./components/CardProduct";

function App() {
  const { i18n } = useTranslation();

  // ** Change language and direction of document
  useEffect(() => {
    document.documentElement.lang = i18n.language;

    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
  }, [i18n.language]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <NavBar />
        <Header />
        <Quote />
        <CardProduct />
        <Content />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
