import React from "react";
import Card from "@mui/material/Card";
import Grid2 from "@mui/material/Grid2";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import CategoryIcon from "@mui/icons-material/Category";
import BuildIcon from "@mui/icons-material/Build";
import TerrainIcon from "@mui/icons-material/Terrain";
import StarIcon from "@mui/icons-material/Star";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import { Button, Container } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  margin: "0px 2px",
}));

const CardProduct = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        py: { xs: 8, sm: 10, md: 15 }, // Adjusting padding for mobile devices
        px: { xs: 1, sm: 2 }, // Adjusting padding for mobile devices
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Container>
        <Card
          sx={{
            borderRadius: "15px",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
            backgroundColor: (theme) => theme.palette.background.paper,
            px: { xs: 2, sm: 4, md: 8 },
            py: { xs: 4, sm: 5 },
          }}
        >
          <Grid2 container spacing={{ xs: 2, sm: 4 }}>
            <Grid2
              item
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <CardContent>
                <Typography
                  variant="h4" // Smaller header for mobile
                  sx={{
                    mb: 4.5,
                    color: (theme) => theme.palette.primary.main,
                    fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }, // Responsive font size
                  }}
                >
                  {t("Product")}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "gray",
                    fontSize: { xs: "0.9rem", sm: "1rem" }, // Adjust font size for smaller screens
                  }}
                >
                  {t("ProductDescription1")}
                  {t("ProductDescription2")}
                  {t("ProductDescription3")}
                </Typography>
              </CardContent>
            </Grid2>
            <Grid2
              item
              size={{ xs: 12, md: 6 }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: (theme) => theme.palette.action.hover,
                p: { xs: 4, sm: 6, md: 8 },
                borderRadius: "15px",
              }}
            >
              <CardContent>
                <Grid2 container spacing={{ xs: 2, sm: 4 }}>
                  <Grid2 item xs={6}>
                    <StyledBox>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <LocalCafeIcon />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 3,
                            fontWeight: "semibold",
                            fontSize: { xs: "1.1rem", sm: "1.3rem" }, // Adjust font size for mobile
                          }}
                        >
                          {t("Product")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            fontSize: { xs: "0.8rem", sm: "1rem" }, // Adjust font size for mobile
                          }}
                        >
                          {t("ProductName")}
                        </Typography>
                      </Box>
                    </StyledBox>
                  </Grid2>
                  <Grid2 item xs={6}>
                    <StyledBox>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <CategoryIcon />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 3,
                            fontWeight: "semibold",
                            fontSize: { xs: "1.1rem", sm: "1.3rem" },
                          }}
                        >
                          {t("Variety")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            fontSize: { xs: "0.8rem", sm: "1rem" },
                          }}
                        >
                          {t("VarietyType")}
                        </Typography>
                      </Box>
                    </StyledBox>
                  </Grid2>
                  <Grid2 item xs={6}>
                    <StyledBox>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <BuildIcon />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 3,
                            fontWeight: "semibold",
                            fontSize: { xs: "1.1rem", sm: "1.3rem" },
                          }}
                        >
                          {t("Processing")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            fontSize: { xs: "0.8rem", sm: "1rem" },
                          }}
                        >
                          {t("ProcessingType")}
                        </Typography>
                      </Box>
                    </StyledBox>
                  </Grid2>
                  <Grid2 item xs={6}>
                    <StyledBox>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <TerrainIcon />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 3,
                            fontWeight: "semibold",
                            fontSize: { xs: "1.1rem", sm: "1.3rem" },
                          }}
                        >
                          {t("Altitude")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            fontSize: { xs: "0.8rem", sm: "1rem" },
                          }}
                        >
                          {t("AltitudeType")}
                        </Typography>
                      </Box>
                    </StyledBox>
                  </Grid2>
                  <Grid2 item xs={6}>
                    <StyledBox>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <StarIcon />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 3,
                            fontWeight: "semibold",
                            fontSize: { xs: "1.1rem", sm: "1.3rem" },
                          }}
                        >
                          {t("Score")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            fontSize: { xs: "0.8rem", sm: "1rem" },
                          }}
                        >
                          {t("ScoreType")}
                        </Typography>
                      </Box>
                    </StyledBox>
                  </Grid2>
                  <Grid2 item xs={6}>
                    <StyledBox>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <EmojiFoodBeverageIcon />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 3,
                            fontWeight: "semibold",
                            fontSize: { xs: "1.1rem", sm: "1.3rem" },
                          }}
                        >
                          {t("Flavors")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& svg": { color: "primary.main" },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "gray",
                            fontSize: { xs: "0.8rem", sm: "1rem" },
                          }}
                        >
                          {t("FlavorsType")}
                        </Typography>
                      </Box>
                    </StyledBox>
                  </Grid2>
                </Grid2>
              </CardContent>
            </Grid2>
          </Grid2>
          <Divider sx={{ my: 4 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                color: "white",
                backgroundColor: (theme) => theme.palette.primary.main,
                textDecoration: "none",
                borderRadius: "12px",
                transition: "ease-in-out 0.5s",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.secondary.main,
                },
              }}
              as="a"
              href="https://api.whatsapp.com/send?phone=966502093001"
              target="_blank"
            >
              {t("ContactUs")}
            </Button>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default CardProduct;
