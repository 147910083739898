import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// ** Material UI Components
import {
  Box,
  Typography,
  Link,
  Grid2,
  Divider,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InstagramIcon from "@mui/icons-material/Instagram";
// ** Internationalization
import { useTranslation } from "react-i18next";

const CustomLink = styled(Link)({
  "&:hover": {
    color: "secondary.main",
    textDecoration: "underline",
  },
  textDecoration: "none",
});
const CustomTypography = styled(Typography)({
  fontFamily: "Arabic",
});

const BrazilFooter = styled("footer")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main, // Use theme's default background
  color: "white",
  padding: theme.spacing(2.5, 0), // Spacing utility (20px = 2.5 * 8px)
  minHeight: "50vh",
  fontFamily: theme.typography.fontFamilyArabic || "Arabic", // Use custom font family if defined
  transition: "ease-in-out 0.5s",
}));

const Footer = () => {
  const { t } = useTranslation();
  return (
    <BrazilFooter>
      <Container>
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, sm: 4, md: 6 }}>
            <Link href="/">
              <img
                width={70}
                height={70}
                src="/barazil-logo.png"
                alt="Brazil Castelo"
              />
            </Link>
            <br />
          </Grid2>

          <Grid2
            size={{ xs: 12, sm: 4, md: 6 }}
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 1,
            }}
          >
            <Box
              sx={{
                px: 4,
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  padding: 6,
                  display: "inline-block",
                  position: "relative",

                  borderRadius: "12px",

                  overflow: "hidden",
                }}
              >
                {/* Top Quotation Mark */}
                <Box
                  sx={{
                    top: "-1rem",
                    left: "2rem",
                    position: "absolute",
                    fontSize: "3rem",

                    zIndex: 1,
                  }}
                >
                  &ldquo;
                </Box>

                {/* Bottom Quotation Mark */}
                <Box
                  sx={{
                    bottom: "-1rem",
                    right: "2rem",
                    position: "absolute",
                    fontSize: "3rem",

                    zIndex: 1,
                  }}
                >
                  &rdquo;
                </Box>

                {/* Quote Text */}
                <CustomTypography
                  variant="h4"
                  sx={{
                    position: "relative",
                    zIndex: 2,

                    fontSize: { xs: "1.2rem", lg: "1.7rem" },
                    fontWeight: "bold",
                    width: "100%",
                    padding: "5px 10px",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {t("quote")}
                </CustomTypography>
              </Box>
            </Box>
          </Grid2>
          {/* Social Media */}
          <Grid2 size={{ xs: 12 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                fontSize: "1.5rem",
              }}
            >
              <Link
                href="https://www.instagram.com/brazilcastelo/"
                target="_blank"
                aria-label="Instagram"
              >
                <InstagramIcon
                  sx={{
                    color: "#dcc793",
                    "&hover": {
                      color: "primary",
                    },
                  }}
                />
              </Link>
            </Box>
          </Grid2>
        </Grid2>

        <Divider
          sx={{
            my: 2,
            backgroundColor: "white !important",
          }}
        />

        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, sm: 4, md: 6 }}>
            <CustomTypography variant="h6" gutterBottom>
              {t("About Us")}
            </CustomTypography>
            <CustomTypography variant="body2" sx={{ textAlign: "justify" }}>
              {t("description")}
            </CustomTypography>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 4, md: 6 }} justifyContent={"center"}>
            <CustomTypography variant="h6" gutterBottom>
              {t("Contact Us")}
            </CustomTypography>

            <CustomTypography variant="body2">
              {t("Phone")}: {t("Number")}
            </CustomTypography>
          </Grid2>
        </Grid2>

        <Divider
          sx={{
            my: 2,
            backgroundColor: "white !important",
          }}
        />

        {/* Copyright Section */}
        <Box
          sx={{
            textAlign: "center",
            marginTop: "20px",

            paddingTop: "10px",
          }}
        >
          <CustomTypography variant="body2">
            © {new Date().getFullYear()} Brazil Castelo. {t("Copyright")}
          </CustomTypography>
          <p>
            {t("by")}{" "}
            <CustomLink
              href="https://www.linkedin.com/in/shimaa-eldakhakhny-2b1b8b234/"
              target="_blank"
            >
              Shimaa Mohamed
            </CustomLink>
            {" & "}
            <CustomLink
              href="https://www.linkedin.com/in/mahmoudali01/"
              target="_blank"
            >
              Mahmoud Ali
            </CustomLink>
          </p>
        </Box>
      </Container>
      <Link
        href="https://api.whatsapp.com/send?phone=966502093001"
        target="_blank"
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "rgb(230,218,206)",
          color: (theme) => theme.palette.primary.main,

          borderRadius: "50%",
          padding: "15px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: "rgb(230,218,206)",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <WhatsAppIcon fontSize="large" sx={{ margin: "auto" }} />
      </Link>
      <Link
        href="https://www.instagram.com/brazilcastelo/"
        target="_blank"
        sx={{
          position: "fixed",
          bottom: "100px",
          right: "20px",
          backgroundColor: "rgb(230,218,206)",
          color: (theme) => theme.palette.primary.main,

          borderRadius: "50%",
          padding: "15px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: "rgb(230,218,206)",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <InstagramIcon fontSize="large" sx={{ margin: "auto" }} />
      </Link>
    </BrazilFooter>
  );
};

export default Footer;
